import {Modal,Row,Col,Form} from 'react-bootstrap';
import ModalHeaderComponent from './ModalHeaderComponent';
import ModalInputComponent from './ModalInputComponent';
import ModalPhotoDisplayComponent from './ModalPhotoDisplayComponent';
import ModalDocumentsComponent from './ModalDocumentsComponent'
import ModalCloseComponent from './ModalCloseComponent';
import ModalComponent from './ModalComponent';
import { useEffect, useState } from 'react';

export default function AnalysisDetailModalComponent({showModal,setShowModal,data,product,category,img}) {
    const handleClose = () => setShowModal(false);
    const [activeCategory,setActiveCategory]=useState('');
    const date = data?.date?.split(" ")[0];
    useEffect(() => {
        if (category) {
            if (category==="Analysis") {
                setActiveCategory("Toprak");
            }else if (category==="Fertilizations") {
                setActiveCategory("Gübreleme");                
            }else if (category==="Spraying") {
                setActiveCategory("İlaçlama");
            }
        }
    }, [category]);
  return (
<>
    <ModalComponent showModal={showModal} handleClose={handleClose}>
      <ModalHeaderComponent title={data?.name} img={img} subTitle={product} logo={"Products"}></ModalHeaderComponent>
        <ModalCloseComponent handleClose={handleClose}></ModalCloseComponent>
         <Modal.Body className='px-4'>
            <Row className='position-relative h-100'>
                <Col xxl={6} xl={6} lg={6}>
                <Form>
                    <ModalInputComponent label={`${activeCategory} Tarihi`} placeholder={date&&date} ></ModalInputComponent>
                    <ModalInputComponent label={`${activeCategory === "Toprak" ?  activeCategory + " Analiz Sonucu" : "Kullanılan Miktar"}`} placeholder={data?.result} ></ModalInputComponent>
                    <Form.Group className="mb-2">
                        <Form.Label>Açıklama</Form.Label>
                        <Form.Control as="textarea" style={{resize: 'none'}} rows={10} placeholder={data?.explanation} disabled className='p-3'/>
                    </Form.Group>
                </Form>
                </Col>
                    <div className='position-absolute bg-black h-100 p-0 m-0 x-line' style={{width:"1px",left:"50%"}}></div>
                    <div className='position-absolute p-0 m-0  w-100 d-flex justify-content-center align-items-center' style={{height:"1px",top:"-2%"}}>
                        <div className='bg-black h-100 y-line' style={{width:"98%"}}></div>
                    </div>
                <Col xxl={6} xl={6} lg={6} className='h-100'>
                {data?.files?.filter(file => file?.extension === ('.png' || '.jpg' || '.jpeg')).length > 0 &&  <ModalPhotoDisplayComponent label={`${activeCategory === "Toprak" ? "Analiz":activeCategory} Fotoğrafları`} customClass="production-photos" images={data?.files} />}
                <div className='overflow-y-auto'>{data?.files?.filter(file => file?.extension === '.pdf').length > 0 && <ModalDocumentsComponent label={"Ekler"} data={data?.files} ></ModalDocumentsComponent>}</div>
                </Col>
            </Row>
      </Modal.Body> 
  </ModalComponent> 
  </>
  )
}
