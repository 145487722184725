/* eslint-disable react-hooks/exhaustive-deps */
import "../styles/StockPage.css"
import CustomizedComponent from '../components/Shared/CustomizedComponent';
import ScrollBarComponent from "../components/Shared/ScrollBarComponent";
import CardComponent from "../components/Shared/CardComponent";
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useProductsState } from "../store/accessors";
import { searchFilter, sortData } from "../services/utils";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProducts } from "../services/apis/product";

export default function StockPage() {

  const stock = useProductsState();

    useEffect(async() => {
      if (stock?.length>0) {
        setLoading(false);
      } else {
        var result = await getProducts();
        setLoading(result && false );
      }
      }, []);

  const [loading,setLoading]=useState(true);
  const history = useHistory();
  const searchValue = useSelector((state) => state.search.value);
  const cardClick = (id)=>{history.push(`/stock/${id}`)}

  const [filterItems,setFilterItems]=useState([
    {
      id:"5656156156154544",
      name:"50 Kg veya daha fazla olanlar",
      stock:50,
      selected:false
    },
    {
      id:"4629486315894986",
      name:"100 Kg veya daha fazla olanlar",
      stock:100,
      selected:false
    },
  ]);

  const [filterList,setFilterList]=useState(stock);

  const sortClick = (desc) => {
    const sortedStock = sortData(filterList, 'maxStock', desc);
    setFilterList(sortedStock);
  };

  const filterClick = (item)=>{
    const itemList=filterItems;
    const findItem=itemList.find(x=>x.id===item.id);
    findItem.id=item.id;
    setFilterItems(itemList);
    setFilterList(filteredStocks(stock,itemList));

  }

    const filteredStocks = (stock, items) => {
    const selectedItems = items.filter((x) => x.selected === true);
    const filteredStocks = stock.filter((product) => {
    return selectedItems.every((selectedItem) => {
      return parseInt(product?.maxStock) >= parseInt(selectedItem?.stock);
    });
  });

  return filteredStocks;
  };

  const matchingProducts = filterList?.filter((s) => searchFilter(s, searchValue) && s?.minStock);
  const matchingProductsCount = matchingProducts?.length;

  useEffect(()=>{
    setFilterList(stock);
  },[stock])

    function convertedDateFormat(item) {
      return new Date(Date.parse(item+" UTC")).toLocaleString("tr-TR")
    }

  return (
    <div className='px-4' style={{overflowY:"hidden"}}>
    <Row className='w-100'>
            <div className='w-100 p-0'>
                <CustomizedComponent filterItems={filterItems} filterClick={filterClick} click={sortClick}/>
            </div>
            <ScrollBarComponent>
                <Row className='gx-5 px-2 ms-1 gy-4 py-4 pe-0' style={{width:"95%"}}>
                  <>
                  {loading ? <div className="fs-1 w-100 d-flex justify-content-center align-items-center" style={{height:"50vh"}}>Yükleniyor...</div> : 
                    (matchingProductsCount > 0 ? <>{matchingProducts?.map((item, index) => ( 
                      <Col key={index} xxl={4} xl={4} lg={4} className='m-0 mb-3 mt-2 card-container'>
                        <Row className='h-100 pages-card-container'>
                        {<CardComponent logo={"Products"} topInfo={`${item?.minStock && item?.maxStock ?  " Min : "+ item?.minStock + (item?.stockUnit ==="Kilogram"?" Kg":"") +  " - Maks : " + item?.maxStock+ " "+  (item?.stockUnit ==="Kilogram"?" Kg":"")  : ""}`} description={`${item?.updateClock && "Güncelleme Saati : " + convertedDateFormat(item?.updateClock)}  `} shadow={"stock-page-shadow"} data={item} cardClick={cardClick}/>}  
                        </Row>
                      </Col>
                    ))} </> :<div className="fs-1 w-100 d-flex justify-content-center align-items-center ps-5" style={{height:"50vh"}}>Pazar kurulumu yok ya da ürün mevcut değil.</div>)
                  }
                  </>
                </Row>
            </ScrollBarComponent>
    </Row>
</div>
  )
}
