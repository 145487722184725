import {Modal,Row,Col,Form} from 'react-bootstrap';
import ModalInputComponent from '../Modals/ModalInputComponent';
import ButtonGroupComponent from '../Modals/ModalButtonGroupComponent';
import ModalHeaderComponent from '../Modals/ModalHeaderComponent';
import ModalPhotoDisplayComponent from '../Modals/ModalPhotoDisplayComponent';
import ModalCloseComponent from '../Modals/ModalCloseComponent';
import ModalComponent from '../Modals/ModalComponent';

import ModalAnalysisDetailComponent from '../Modals/ModalAnalysisDetailComponent';
import { useState } from 'react';

export default function ModalStandProductDetailComponent({showModal,setShowModal,data,farmer,standNumber,qrCode}) {


    const [showAnalysisModal,setShowAnalysisModal] = useState(null); 
    const [selectedAnalysis,setSelectedAnalysis] = useState({}); 
    const [category,setCategory] = useState(''); 
    const date = data?.harvestDate?.split(" ")[0];

    const handleClose = () => setShowModal(false);

    const analysisClick = (id,index,category)=>{
        setSelectedAnalysis(data.analysis[parseInt(index)]);
        setShowAnalysisModal(true);
        setCategory(category);
    }
    const fertilizationsClick = (id,index,category)=>{
        setSelectedAnalysis(data.fertilizations[parseInt(index)]);
        setShowAnalysisModal(true); 
        setCategory(category); 
    }
    const sprayingClick = (id,index,category)=>{
        setSelectedAnalysis(data.spraying[parseInt(index)]);
        setShowAnalysisModal(true); 
        setCategory(category);
    }
  return (
     <>
        <ModalComponent showModal={showModal} handleClose={handleClose}>
            <ModalHeaderComponent title={data?.name} subTitle={farmer} img={data?.productLogo&&data?.productLogo} logo={"Products"} standNumber={standNumber} qrCode={qrCode}/>
            <ModalCloseComponent handleClose={handleClose}></ModalCloseComponent>
            <Modal.Body className='px-4'>
                <Row className='position-relative h-100'>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form>
                            <Row>
                                <Col xxl={6} xl={6} lg={6}>
                                    <ModalInputComponent label={"Stok Takip"} placeholder={`${data?.stock +" "+ (data?.stockUnit&&(data?.stockUnit==="Kilogram"?"Kg":""))}`} ></ModalInputComponent>
                                </Col>
                                <Col xxl={6} xl={6} lg={6}>
                                    <ModalInputComponent label={"Birim Fiyat"} placeholder={`${(parseFloat(data?.unitPrice).toFixed(2) + " ₺")}`} ></ModalInputComponent>
                                </Col>
                            </Row>
                            <ModalInputComponent label={"Üretildiği Yerler"} placeholder={data?.whereProduced} />
                            <ModalInputComponent label={"Hasat Tarihi"} placeholder={date&&date} />
                            {data?.analysis?.length>0 && <ButtonGroupComponent category={"Analysis"} click={analysisClick} categoryId={1} label={"Toprak Analizi"} data={data?.analysis}/>}
                            {data?.fertilizations?.length>0 &&<ButtonGroupComponent category={"Fertilizations"} click={fertilizationsClick} categoryId={2} label={"Gübreleme"} data={data?.fertilizations}/>}
                            {data?.spraying?.length>0 &&<ButtonGroupComponent category={"Spraying"} click={sprayingClick} categoryId={3} label={"İlaçlama"} data={data?.spraying}/>}
                        </Form>
                    </Col>

                    {/* Ayraçlar */}
                    <div className='position-absolute bg-black h-100 p-0 m-0 x-line' style={{width:"1px",left:"50%"}}></div>
                        <div className='position-absolute p-0 m-0  w-100 d-flex justify-content-center align-items-center' style={{height:"1px",top:"-2%"}}>
                            <div className='bg-black h-100 y-line' style={{width:"98%"}}></div>
                        </div>
                    {/* Ayraçlar */}

                    <Col xxl={6} xl={6} lg={6} className='h-100 d-flex flex-column justify-content-between'>
                    {data?.productImageList?.filter(file => file?.extension === '.jpg' || '.png' || 'jpeg')?.length > 0  &&   <ModalPhotoDisplayComponent label={"Ürettiğim Ürünler"} customClass="production-photos" images={data?.productImageList} />}
                    </Col>
                </Row>
            </Modal.Body>
        </ModalComponent>
    <ModalAnalysisDetailComponent img={data?.productLogo&&data?.productLogo} category={category} data={selectedAnalysis} showModal={showAnalysisModal} setShowModal={setShowAnalysisModal} product={data?.name}/>
    </>

  )
}
