import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import ModalOneItemShowSliderComponent from "./ModalOneItemShowSliderComponent";
import DownIcon from "../Icons/DownIcon";
import config from '../../services/config'
import { Image } from "react-bootstrap";


const SliderComponent = ({customClass,slidesPerView,size,images,click,marketInfoImages,initialSlide}) => {
  const [showPhotoItemModal, setShowPhotoItemModal] = useState(false);
  const [clickedItem,setClickedItem] = useState(0);

  const handleClick = (swiper)=>{
    const clickedIndex =  swiper.clickedIndex;   
    setClickedItem(clickedIndex);
    if (click !== false) {
      setShowPhotoItemModal(true)
    }
  }

  return (
    <section className="swiper-container h-100 w-100 p-1" style={{height:"100%",cursor:"pointer"}} >
      <Swiper
        onClick={handleClick}
        onSlideClick={handleClick}
        initialSlide={initialSlide?initialSlide:0}
        slidesPerView={slidesPerView}
        spaceBetween={20}
        modules={[Navigation]}
        className="mySwiper d-flex text-center w-100 h-100"
        navigation={{
          nextEl: `.${customClass}-next`,
          prevEl: `.${customClass}-prev`,
        }}
      >
      {images ? images?.map((image,index) => (
          image?.extension === ".png" ||  image?.extension === ".jpg" || image?.extension === ".jpeg" ?
        <SwiperSlide key={index} className="swiper-slider-container d-flex align-items-center">
          <Image src={`${config.API_URL}/file/${image?.id?image?.id:image}`} className="slider-full container rounded w-100 h-100"/>
        </SwiperSlide>:null
        ))
        :
        marketInfoImages && marketInfoImages?.map((image,index) => (
           <SwiperSlide key={index} className="d-flex justify-content-center align-items-center">
             <Image src={`/assets/images/${image}.png`} className="w-75 m-0 p-0 container" style={{height:"90%",width:"20%"}}/>
           </SwiperSlide>
         ))   
        }

      </Swiper>
      <div
        className={`${customClass}-next position-absolute `}
        style={{
          right: `${slidesPerView === 1 ? "-120px" :  "-50px"} `,
          backgroundColor: "transparent",
          top: `${slidesPerView === 1 ? "40%" :  "30%"}`,
          cursor: "pointer",
          transform: "rotate(180deg)",

        }}
      >
        <DownIcon size={size}/>
      </div>
      <div
        className={`${customClass}-prev position-absolute`}
        style={{
          left: `${slidesPerView === 1 ? "-120px" :  "-50px"} `,
          backgroundColor: "transparent",
          top: `${slidesPerView === 1 ? "40%" :  "30%"}`,
          cursor: "pointer",
        }}
      >
        <DownIcon size={size}/>
      </div>
     <ModalOneItemShowSliderComponent clickedIndex={clickedItem} showModal={showPhotoItemModal} setShowModal={setShowPhotoItemModal} images={images} marketInfoImages={marketInfoImages} ></ModalOneItemShowSliderComponent> 
    </section>
  );
};

export default SliderComponent;
