/* eslint-disable react-hooks/exhaustive-deps */
import CustomizedComponent from '../components/Shared/CustomizedComponent'
import ScrollBarComponent from '../components/Shared/ScrollBarComponent';
import CardComponent from '../components/Shared/CardComponent';
import { Col, Row } from 'react-bootstrap'
import { useParams  } from 'react-router-dom';
import { useProductsState } from '../store/accessors';
import { searchFilter, sortData } from '../services/utils';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getProducts } from '../services/apis/product';
import { useEffect } from 'react';
import DownIcon from '../components/Icons/DownIcon';
import { useHistory } from 'react-router-dom';

export default function StockDetailPage() {
  
  const {id} = useParams();
  const stock = useProductsState(id);
  useEffect(async() => {
    if (stock) {
      setLoading(false);
    } else {
      var result = await getProducts();
      setLoading(result && false );
    }
    }, []);

    const [loading,setLoading]=useState(true);
    const product = stock?.name;

    const searchValue = useSelector((state) => state.search.value);
    const [filterItems,setFilterItems]=useState([
        {
          id:"5656156156154544",
          name:"20 Kg veya daha fazla olanlar",
          stock:20,
          selected:false
        },
        {
          id:"4629486315894986",
          name:"40 Kg veya daha fazla olanlar",
          stock:40,
          selected:false
        },
      ]);
    
  const [filterList,setFilterList]=useState(stock?.theStalls);

    const sortClick = (desc) => {
        const sortedStalls = sortData(stock?.theStalls, 'stock',desc);
        setFilterList(sortedStalls);
      }

      const filterClick = (item)=>{
        const itemList=filterItems;
        const findItem=itemList.find(x=>x.id===item.id);
        findItem.id=item.id;
        setFilterItems(itemList);
        setFilterList(filteredStock(stock?.theStalls,itemList))
      }
    
      const filteredStock = (theStalls, items) => {
      const selectedItems = items.filter((x) => x.selected === true);
      const filteredStock = theStalls.filter((theStalls) => {
      return selectedItems.every((selectedItem) => {
        return parseInt(theStalls?.stock) >= parseInt(selectedItem?.stock);
      });
    });
    
    return filteredStock;
    };

    useEffect(() => {
      setFilterList(stock?.theStalls);
    }, [stock]);
    
    const history = useHistory();
    return (
    <>  {
        filterList?.length<1 ?<>  <button onClick={()=>{history.goBack()}} className='m-4 p-0 border-0 bg-transparent'><DownIcon size={40}/></button><div className='fs-1 w-100 h-75 d-flex justify-content-center align-items-center'>Tezgah Bilgisi Bulunamadı...</div></> : 
        <div className='px-4' style={{overflow:"hidden"}} >
        <Row className='w-100'>
                <div className='w-100 p-0 d-flex'>
                  <button onClick={()=>{history.goBack()}} className='m-0 p-0 border-0 bg-transparent'><DownIcon size={40}/></button>
                    <CustomizedComponent filterClick={filterClick} filterItems={filterItems} click={sortClick}/>
                </div>
            <ScrollBarComponent>
                <Row className='gx-5 px-2 ms-1 gy-4 py-4 pe-0' style={{width:"95%"}}>
                <>
                  {loading ? <div className="fs-1 w-100 d-flex justify-content-center align-items-center" style={{height:"50vh"}}>Yükleniyor...</div> : <>  {filterList?.filter((item) => searchFilter(item,searchValue)).map((item, index) => ( 
                        <Col key={index} xxl={4} xl={4} lg={4} className='m-0 mb-3 mt-2 card-container'>
                            <Row className='h-100 pages-card-container'>
                                <CardComponent logo={"Products"} description={product} shadow={"stock-page-shadow"} topInfo={`${item?.stock + (item?.stockUnit ==="Kilogram"?" Kg":"")}`} bottomInfo={`${(parseFloat(item?.price).toFixed(2) + " ₺")}`} data={item}/>
                            </Row>
                        </Col>
                    ))} </> }
                  </>
                </Row>
            </ScrollBarComponent>
        </Row>
    </div>}
    </> )
}
