import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ModalComponent from "../Modals/ModalComponent";
import ModalCloseComponent from "../Modals/ModalCloseComponent";
import config from "../../services/config";
import "react-pdf/dist/esm/Page/TextLayer.css";

export default function PdfViewerComponent({
  showModal,
  setShowModal,
  documentId,
}) {
  useEffect(() => {
    const workerSrc = "assets/ajax/pdf.worker.js";
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
  }, []);

  const [numPages, setNumPages] = useState(null);

  const handleClose = () => setShowModal(false);

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <ModalComponent
      showModal={showModal}
      handleClose={handleClose}
      backgroundColor="rgba(0, 67, 140, 0.5)"
    >
      <ModalCloseComponent handleClose={handleClose} />
      <div className="m-5 overflow-auto">
        <Document
          file={`${config.API_URL}/file/${documentId}`}
          onLoadSuccess={handleLoadSuccess}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="pdf-pages-container">
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="pdf-page"
              />
            ))}
          </div>
        </Document>
      </div>
    </ModalComponent>
  );
}
