/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import Image from 'react-bootstrap/Image'
import market from "../assets/market-info.png"
import { Col, Row } from 'react-bootstrap'
import ModalSliderComponent from "../components/Modals/ModalSliderComponent"
import { useMarketInfoState } from '../store/accessors'
import { useEffect } from 'react'
import {getMarketInfos} from "../services/apis/marketInfo"
export default function MarketInfoPage() {
    const marketInfoImages = ["market-info-slider-item-1","market-info-slider-item-2","market-info-slider-item-3","market-info-slider-item-1","market-info-slider-item-2","market-info-slider-item-3","market-info-slider-item-1","market-info-slider-item-2","market-info-slider-item-3",]
    const [loading,setLoading]=useState(true);
    
    const marketInfo = useMarketInfoState();
  
    useEffect(async() => {
      if (marketInfo?.length>0) {
        setLoading(false);
      } else {
        var result = await getMarketInfos();
        setLoading(result && false );
      }
      }, []);


  return (
    <section style={{height:"70vh"}}>
        <Row className='m-5' style={{height:"27vh"}}>
            <Col xxl={12} xl={12} lg={12} className='d-flex flex-row h-100'>
                <Image src={market} alt="market bilgi" className='w-50 h-100'/>
                <p className='w-100 market-info-description  fw-semibold ms-5 d-flex align-items-center h-100 overflow-auto d-inline-block text-break'>
                    <>{loading ? <span className='text-center w-100'>Yükleniyor...</span>:<>
                    {marketInfo[0]?.description ? marketInfo[0]?.description :" İstanbul Büyükşehir Belediyesi Tarımsal Hizmetler Dairesi Başkanlığı Tarım ve Su Ürünleri Müdürlüğü, özellikle İstanbul’da üretim yapan çiftçilerin ürünlerini direkt tüketicilere pazarlaması amacıyla, üretici ve kooperatif pazarı kurdu. "}
                    </>}</>
                </p>
            </Col>
        </Row>
        <div className='d-flex justify-content-center' style={{height:"35vh"}}>
            <div className='position-relative' style={{margin:"0rem 5rem",width:"90%"}}>
                <ModalSliderComponent marketInfoImages={marketInfoImages} slidesPerView={3} size={70} customClass={"market-info"}/>
            </div>
        </div>
        
    </section>
  )
}
